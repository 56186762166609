<template>
  <v-app class="wallpaper">
    <!-- <v-app style="background-color:#003333 !important"> -->
    <!-- eslint-disable -->
    <v-row
    class="mx-auto my-auto d-flex justify-content-center bg-dark col-md-8"
    >
      <v-col class="my-auto mx-auto col-md-5"
      >
        <base-material-card
          class="v-card-profile col-md-12"
          :avatar="image"

        >
          <v-card-text class=" justify-content-center">
            <form class="text-center mx-auto justify-content-center  align-content-center "
             @submit.prevent="resetPass"
             @keydown="form.onKeydown($event)"
             >
              <v-text-field
              v-model="form.password" class="col-md-12"
              type="password"
              label="Enter Password"
              prepend-icon="mdi-lock"
              required
              />
              <v-text-field
              v-model="form.rpassword" class="col-md-12"
              type="password"
              label="Re-enter Password"
              prepend-icon="mdi-lock"
              required
              />
              <!-- <v-text-field
              v-model="form.password" class="col-md-12"
              type="password"
              label="Password"
              prepend-icon="mdi-lock"
              required
              /> -->
              <!-- <v-row class="col-md-10">
              <a>Forgot Password?</a> </v-row>
              <v-spacer></v-spacer> -->
            <v-btn
              color="#003333"
              rounded
              :loading="loading"
              class="mr-0"
              type="submit"
              large
            >
              Change Password
            </v-btn>
              <!-- <v-btn text to="/forgotPassword">Back</v-btn> -->

            </form>
          </v-card-text>
        </base-material-card>
      </v-col>
      <v-snackbar
      v-model="snackbar"
      :bottom="true"
      :left="false"
      :color="color"
      :right="false"
      :timeout="timeout"
      :top="false"
      :vertical="false"
       class="justify-content-center"
       >
      <span style="margin-left:20% !important; color:#003333 !important; font-size:16px !important">{{ text }}</span>
      <!-- <v-btn
        color="black"
        text
        @click="snackbar = false"
      >
        Close
      </v-btn> -->
    </v-snackbar>
    </v-row>
  </v-app>
</template>

<script>
/* eslint-disable */
  import Vue from 'vue'
  import router from '../router'
  import { Form, HasError, AlertError } from 'vform'
  Vue.component(HasError.name, HasError)
  Vue.component(AlertError.name, AlertError)
  export default {
    name: 'Login',
    data: () => ({
      image: require('@/assets/munjz_logo.png'),
      loading: false,
      snackbar: false,
      y: 'top',
      x: null,
      mode: '',
      timeout: 2000,
      text: 'Hello, I\'m a snackbar',
      color: '',
      form: new Form({
        email: '',
        password: '',
        rpassword: '',
        remember: false,
      }),
    }),
    mounted () {
      localStorage.removeItem('signedin')
      localStorage.removeItem('userid')
      localStorage.removeItem('name')
      localStorage.removeItem('email')
    },
    methods: {
      resetPass () {
        // Submit the form via a POST requesthttps://munjz.info/api/v3/store/login
        if (this.form.password == this.form.rpassword){
        this.loading = true
        this.form.post('/store/merchantUpdatePassword?reset_token='+this.$route.query.token)
          .then(({ data }) => {
            if (data.statusCode === 400) {
              this.text = data.msg
              this.color = 'white'
              this.snackbar = true
            } else if (data.statusCode === 200) {
              this.text = data.msg
              this.color = 'teal'
              this.snackbar = true
              // localStorage.setItem('signedin', true)
              // localStorage.setItem('userid', data.user_id)
              // //   localStorage.setItem('name', data.name)
              // localStorage.setItem('email', data.email)
              router.push('/')
            }
          }).catch((error) => {
            console.log(error)
          }).finally(this.loading = false)
        }else{
          this.text = 'Password Does not Match'
          this.color = 'error'
          this.snackbar = true
        }
      },
    },
  }
</script>
<style scoped>
/* .base-material-card.avatar{
background-image:url("../assets/logo.png") !important;} */
.wallpaper{
  /* position: fixed; top: 0; left: 0; */
background-image:url('../assets/desktop-2.png') !important;
 background-repeat: no-repeat !important;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

}
</style>
